<template>
  <div>
    <v-dialog
      transition="scale-transition"
      persistent
      v-model="model"
      max-width="600px"
    >
      <v-card outlined>
        <v-card-title class="title"
          >{{ $t("personalization.title_update") }}
          <div style="position: absolute; right: 25px;">
            <v-icon :disabled="loading" @click="closeDialog(false)"
              >mdi-close</v-icon
            >
          </div></v-card-title
        >
        <v-divider />
        <v-card-text style="height: 150px;">
          <div class="mt-5 mb-2">
            {{ $t("personalization.uid") }} <strong>{{ dataUID.uid }}</strong>
            {{ $t("personalization.msg.confirm_uid.first") }}
            {{ typeUser }}
            <strong>{{ dataUID.name }}</strong> .
            {{ $t("personalization.msg.confirm_uid.second") }}
            <strong>{{ dataUID.uid }}</strong>
            {{ $t("personalization.msg.confirm_uid.three") }}
            {{ typePerson }} <strong>{{ dataUID.personName }}</strong>
            {{ $t("personalization.msg.confirm_uid.four") }}
          </div>
          <div class="error--text">
            {{ $t("personalization.msg.confirm_uid.five") }}
            {{ typeUser }}
            <strong>{{ dataUID.name }}</strong>
            {{ $t("personalization.msg.confirm_uid.six") }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <v-btn
              :disabled="loading"
              outlined
              small
              color="primary"
              class="mr-2"
              @click="closeDialog(false)"
              >{{ $t("app.cancel") }}</v-btn
            >
            <v-btn
              :loading="loading"
              class="gradient-primary"
              dark
              small
              depressed
              @click="saveData"
              @keyup.enter="saveData"
              >{{ $t("app.save") }}</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    model: Boolean,
    dataUID: Object,
    loading: Boolean
  },
  data() {
    return {
      typePerson: "",
      typeUser: this.dataUID.user_type
    };
  },
  watch: {
    dataUID(data) {
      switch (data.personType) {
        case "TEACHER":
          this.typePerson = "Guru";
          break;
        case "STAFF":
          this.typePerson = "Staff";
          break;
        default:
          this.typePerson = "Siswa";
          break;
      }
      switch (data.user_type) {
        case "TEACHER":
          this.typeUser = "Guru";
          break;
        case "STAFF":
          this.typeUser = "Staff";
          break;
        default:
          this.typeUser = "Siswa";
          break;
      }
    }
  },
  methods: {
    closeDialog(isSave) {
      this.$emit("close", isSave);
    },
    saveData() {
      this.$emit("save", { ...this.dataUID, uid_exist: true });
    }
  }
};
</script>
